import { base64toBlob, showPdf } from '@/services/blobutility'
import Button from '@/components/buttons/Button'
import Download from '../component/Download'
import _get from 'lodash/get'
import { documentCenterStore } from '@/services/DocumentCenter/store'
import { parseDate } from '@/utilities/Date/parse'
import { notifyMessage, notifyProblem, notifyWarning } from '@/services/notify'

export const methods = {
  determineRows: async function(list) {
    if (!Array.isArray(list)) {
      return
    }

    var rows = list.map(entry => {
      const category = _get(entry, 'governingDocumentCategory.name', '')
      const documentName = _get(entry, 'description', '')
      const createdDate = parseDate(_get(entry, ['createdDate'], ''), 'LL')
      const governingDocumentID = _get(entry, ['governingDocumentID'], 0)

      return {
        category: category,
        documentName: documentName,
        uploaded: createdDate,
        edit: {
          component: Button,
          props: {
            onClick: () => {
              this.redirectToEdit(entry)
            },
            text: 'Edit'
          }
        },
        delete: {
          component: Button,
          props: {
            onClick: async () => {
              await this.confirmDeleteDocument(governingDocumentID, documentName)
            },
            text: 'Delete'
          }
        },
        document: {
          component: Download,
          props: {
            governingDocumentID: _get(entry, ['governingDocumentID'], 0),
            downloadPdf: governingDocumentID => {
              this.downloadPdf(governingDocumentID)
            }
          }
        }
      }
    })

    this.rows = rows
  },

  createGoverningDocumentModal() {
    this.toggle = true
    this.selectedGoverningDocument = null
  },

  redirectToEdit(governingDocument) {
    this.toggle = true
    this.selectedGoverningDocument = governingDocument
  },

  async confirmDeleteDocument(governingDocumentID, documentName) {
    this.$buefy.dialog.confirm({
      title: 'Deleting Description',
      message: `Are you sure you want to <b>delete</b> this document: "${documentName}"?`,
      confirmText: 'Delete Document',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => this.deleteGoverningDocument(governingDocumentID)
    })
  },

  async deleteGoverningDocument(governingDocumentID) {
    if (governingDocumentID) {
      await documentCenterStore.dispatch('deleteGoverningDocument', {
        governingDocumentID: governingDocumentID,
        done: () => {
          this.loading = false
          this.reload()
        }
      })
    }
  },

  async downloadPdf(governingDocumentID) {
    if (this.isDebug == true) console.debug('downloadPdf...')

    if (!governingDocumentID || governingDocumentID == undefined) {
      notifyProblem('There is a problem downloading this document.')
    }

    try {
      const params = {
        governingDocumentID,
        asBase64: true,
        asRawBase64: true
      }

      await documentCenterStore.dispatch('getDocumentFile', {
        params,
        done: ({ details }) => {
          if (details) {
            if (this.isDebug == true) console.debug('base64pdf........................' + details)

            const contentType = 'application/pdf'
            const blob = base64toBlob(details, contentType)
            showPdf(blob)
            notifyMessage(`Successfully downloaded the governing document.`)
            return
          }
        }
      })
    } catch (e) {
      if (this.isDebug == true) console.debug(e)
      notifyWarning('There was a problem downloading the statements of account.')
    }
  },

  async loadDocuentCenter() {
    if (this.isDebug == true) console.debug('in loadDocuentCenter()...')

    await documentCenterStore
      .dispatch('getDocumentCenterList', {
        hoaID: this.hoaId
      })
      .then(async ({ list }) => {
        await this.determineRows(list)
      })
  },

  async reload() {
    this.loading = true

    this.rows = []
    await this.loadDocuentCenter()

    this.loading = false
  }
}
