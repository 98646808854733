import { documentCenterStore } from '@/services/DocumentCenter/store'
import { notifyError, notifyMessage } from '@/services/notify'
import $ from 'jquery'

export const methods = {
  async reload() {
    this.loading = true

    this.loadCategories()

    if (this.governingDocument && this.governingDocument != undefined) {
      this.documentName = this.governingDocument.description
      this.governingDocumentCategoryID = this.governingDocument.governingDocumentCategoryID

      if (this.governingDocument.documentID && this.governingDocument.documentID != undefined) {
        this.attachment = true
        this.loadCurrentDocument()
      }

      //Update
      this.title = 'Update'

      this.loading = false
    } else {
      this.title = 'Add'
      this.loading = false
    }

    this.loading = false
  },

  open() {
    this.isOpen = true
    this.loading = false
  },

  async loadCategories() {
    await documentCenterStore.dispatch('getDocumentCategoryList').then(async ({ list }) => {
      if (list) {
        this.categoryList = list.map(m => ({
          value: m.governingDocumentCategoryID,
          label: m.name
        }))
      }
    })
  },

  async replaceFile() {
    if (
      this.governingDocument &&
      this.governingDocument != undefined &&
      this.governingDocument.governingDocumentID > 0
    ) {
      const payload = {
        hoaID: this.governingDocument.hoaID,
        governingDocumentID: this.governingDocument.governingDocumentID,
        description: this.governingDocument.description,
        file: this.file
      }

      await documentCenterStore.dispatch('uploadDocument', {
        payload: payload,
        done: ({ returnedFile }) => {
          if (returnedFile) {
            this.reload()
            $('#viewpdf').focus()
            notifyMessage(`Successfully replaced the document.`)
          } else {
            notifyError('There was a problem replacing this document.')
          }
        }
      })
    }
  },

  async loadCurrentDocument() {
    if (
      !this.governingDocument ||
      !this.governingDocument.documentID ||
      this.governingDocument.documentID <= 0
    ) {
      return
    }

    try {
      const params = {
        governingDocumentID: this.governingDocument.governingDocumentID,
        asBase64: true
      }

      await documentCenterStore.dispatch('getDocumentFile', {
        params: params,
        done: ({ details }) => {
          if (details) {
            if (this.isDebug == true)
              console.debug(
                ' - params' + JSON.stringify(params) + ' - this.getDocumentFile=' + details
              )

            this.base64pdf = details
            this.imageContent = null
          }
        }
      })
    } catch (e) {
      if (this.isDebug == true) console.debug(e)
    }
  },

  async onFormSubmit() {
    if (this.documentName && this.documentName != undefined) {
      if (this.governingDocument) {
        await this.updateModal()
      } else {
        await this.addModal()
      }
    }
  },

  closeModal() {
    this.$emit('update:toggle', (this.documentToggle = !this.documentToggle))

    // auto-close modal
    $('#clickclose').click()
  },

  async addModal() {
    if (this.documentName == '') {
      notifyError('Document Name is a required field.')
      return
    }

    this.loading = true

    await documentCenterStore.dispatch('addGoverningDocument', {
      hoaID: this.hoaId,
      documentID: null,
      governingDocumentCategoryID: this.governingDocumentCategoryID,
      description: this.documentName,
      done: async ({ details }) => {
        if (details) {
          this.loading = false
          // auto-close modal
          $('#clickclose').click()
        }
      }
    })
  },

  async updateModal() {
    if (!this.governingDocument || this.governingDocument == undefined) {
      notifyError('There was a problem updating this governing document.')
      return
    }

    console.debug('updateModal...')

    this.loading = true

    const payload = {
      governingDocumentID: this.governingDocument.governingDocumentID,
      hoaID: this.governingDocument.hoaID,
      governingDocumentCategoryID: this.governingDocumentCategoryID,
      description: this.documentName ? this.documentName : null,
      documentID: this.governingDocument.documentID ? this.governingDocument.documentID : null
    }

    await documentCenterStore.dispatch('updateGoverningDocument', {
      payload: payload,
      done: () => {
        // auto-close modal
        this.$emit('update:toggle', (this.toggle = false))
        $('#clickclose').click()
        this.loading = false
      }
    })
  },

  async submitFile() {
    if (this.documentName == '') {
      notifyError('Document Name is a required field.')
      return
    }

    this.loading = true

    try {
      if (this.attachment == false) {
        await documentCenterStore.dispatch('addGoverningDocument', {
          hoaID: this.hoaId,
          documentID: null,
          governingDocumentCategoryID: this.governingDocumentCategoryID,
          description: this.documentName,
          done: async ({ details }) => {
            if (details && details != undefined && details.governingDocumentID > 0) {
              const payload = {
                hoaID: details.hoaID,
                governingDocumentID: details.governingDocumentID,
                description: details.description,
                file: this.file
              }

              console.debug('payload prior to store file=' + JSON.stringify(payload))

              await documentCenterStore.dispatch('uploadDocument', {
                payload: payload,
                done: ({ returnedFile }) => {
                  if (returnedFile) {
                    this.$emit('update:toggle', (this.documentToggle = !this.documentToggle))
                    // auto-close modal
                    $('#clickclose').click()

                    notifyMessage(`Successfully uploaded the document.`)
                  } else {
                    notifyError('There was a problem uploading this document.')
                  }
                }
              })
            }
          }
        })
      }
    } catch (e) {
      notifyError(e)
    }
    this.loading = false
  },

  zoom(direction) {
    const amt = Math.sqrt(2)
    if (direction > 0) {
      this.scale *= amt
    } else {
      this.scale /= amt
    }

    // constrain to min/max
    this.scale = Math.max(0.5, Math.min(4, this.scale))
  }
}
