<template>
  <div governing-document-modal id="governingDocumentModal" class="section uploader">
    <div class="container">
      <div class="is-size-4">{{ `${title} Governing Document` }}</div>
      <div class="box is-12">
        <ValidationObserver ref="form" v-slot="{ handleSubmit }">
          <transition name="fade" mode="out-in" appear>
            <form @submit.prevent="handleSubmit(onFormSubmit)" class="form">
              <fieldset>
                <div class="pt-5" v-if="categoryList">
                  <valid-select
                    label="Category"
                    placeholder="Category"
                    vid="category"
                    required="true"
                    rules="required"
                    v-model="governingDocumentCategoryID"
                  >
                    <option
                      v-for="option in categoryList"
                      :value="option.value"
                      :key="option.value"
                      >{{ option.label }}</option
                    >
                  </valid-select>
                </div>
                <div class="is-12 pt-5">
                  <valid-input
                    style="width:50px !important;"
                    name="DocumentName"
                    label="Document Name"
                    type="text"
                    vid="DocumentName"
                    placeholder="Document Name"
                    spellcheck="true"
                    aria-label="Document Name"
                    rules="max:8000|required"
                    class="is-small"
                    v-model="documentName"
                  />
                </div>
                <div class="box is-8">
                  <section>
                    <div id="viewpdf" v-if="base64pdf" class="has-text-centered">
                      <div>
                        <div>
                          <div
                            v-dragscroll="true"
                            :class="['doc-viewer', { 'zoom-active': scale > 0.9 }]"
                          >
                            <pdf
                              style="display: none;"
                              :src="base64pdf"
                              @num-pages="pageCount = new Array($event)"
                            />
                            <!-- Actual document -->
                            <pdf
                              v-for="(page, index) in pageCount"
                              :key="index"
                              :src="`${base64pdf}`"
                              :page="index + 1"
                              :scale="scale"
                              :resize="true"
                              :style="{ margin: 'auto', width: `${100 * scale}%` }"
                            />
                          </div>
                        </div>
                        <div class="doc-controls has-text-center">
                          <b-field position="is-centered">
                            <div class="control">
                              <b-button @click="zoom(-1)">-</b-button>
                            </div>
                            <div class="control">
                              <b-button @click="zoom(1)">+</b-button>
                            </div>
                          </b-field>
                        </div>
                      </div>
                    </div>
                    <div v-else-if="imageContent">
                      <img :src="imageContent" />
                    </div>
                    <div>
                      <!-- Show the Upload Form -->
                      <b-field class="file">
                        <b-upload
                          v-model="file"
                          drag-drop
                          accept="application/pdf"
                          class="rectangle"
                        >
                          <section class="section">
                            <div class="">
                              <div>
                                <img
                                  class="Fill-1-Copy-11"
                                  src="@/assets/images/fill-1-copy-11.svg"
                                  alt="Upload"
                                />
                              </div>
                              <div v-if="file">
                                {{ file.name }}
                              </div>
                              <div v-else>
                                <div class="Drag-Drop-your-fil">
                                  <div class="text-style-1">Drag & Drop</div>
                                  <div>Please upload PDF files</div>
                                </div>
                              </div>
                            </div>
                          </section>
                        </b-upload>
                      </b-field>
                    </div>
                    <div class="pt-5">
                      <span class="pr-5" v-if="attachment == false && !governingDocument"
                        ><b-button type="is-primary" :disabled="!file" @click="submitFile"
                          >Add/Upload</b-button
                        ></span
                      >
                      <span class="pt-5 pr-5">
                        <button
                          v-if="governingDocument && governingDocument != undefined"
                          type="submit"
                          :disabled="loading"
                          class="button is-primary is-rounded"
                        >
                          {{ title }}
                        </button>
                      </span>

                      <span class="pr-5" v-if="governingDocument && governingDocument != undefined"
                        ><b-button type="is-primary" :disabled="!file" @click="replaceFile"
                          >Replace</b-button
                        ></span
                      >
                      <span><b-button type="is-primary" @click="closeModal">Close</b-button></span>
                    </div>
                  </section>
                </div>
              </fieldset>
              <fieldset>
                <ValidationProvider vid="providerErrors" v-slot="{ errors }">
                  <div class="field">
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </fieldset>
            </form>
          </transition>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { mapState, mapGetters } from 'vuex'
import { data } from './keys/data'
import { methods } from './keys/methods'
import pdf from 'vue-pdf'
import ValidInput from '@/components/inputs/ValidInput'
import ValidSelect from '@/components/inputs/ValidSelect'
import { dragscroll } from 'vue-dragscroll'

export default {
  name: 'GoverningDocumentModal',
  props: {
    governingDocument: Object
  },

  directives: { dragscroll },

  components: {
    ValidInput,
    ValidSelect,
    ValidationObserver,
    ValidationProvider,
    pdf
  },

  mounted() {
    this.reload()
  },

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId,
      ...mapGetters('user', ['authUser'])
    })
  },

  data,
  methods
}
</script>
<style lang="scss">
#governingDocumentModal {
  .modal-h-full {
    left: 50%;
    transform: translate(-50%);
  }

  .doc-viewer {
    display: block;
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 130%;
    min-height: 40vh;
    max-height: calc(100vh - 320px);
    border: 1px solid #dbdbdb;

    &.zoom-active {
      cursor: grab;
    }
  }

  .doc-controls {
    margin-top: -1em;
  }
}

#request-image-upload {
  .upload .upload-draggable {
    border: none 0;
    border-radius: 0;
    width: 100%;
    padding: 0;
  }

  .modal-close,
  .delete {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 20;
    margin: 5px 5px 0 0;
  }
}

.uploader {
  .rectangle {
    width: 244px;
    height: 137px;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0 0px;
    border-radius: 4px;
  }
  .Drag-Drop-your-fil {
    width: 192px;
    height: 34px;
    margin: 7px 0 8px 4px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center !important;
    color: #838383;
  }

  .Drag-Drop-your-fil .text-style-1 {
    font-weight: bold;
  }

  img.Fill-1-Copy-11 {
    width: 24px;
    height: 30px;
    margin: 0 85px 7px 83px;
    object-fit: contain;
  }
}
</style>
