import moment from 'moment'

export const data = () => ({
  isDebug: true,
  loading: false,
  showFilters: false,
  toggle: false,
  selectedGoverningDocument: null,
  documents: [],
  breakpoint: '',
  formatDateFn: function(value) {
    return value != null ? moment(value, 'YYYY/MM/DD HH:mm:ss').format('MM-DD-YYYY') : ''
  },

  filters: {
    show: false
  },

  region: {},

  rows: [],

  columns: [
    {
      field: 'category',
      label: 'Category',
      aria: 'Category',
      width: '15%',
      sortable: true,
      searchable: true
    },
    {
      field: 'documentName',
      label: 'Document Name',
      aria: 'Document Name',
      width: '75%',
      sortable: true,
      searchable: true
    },
    {
      field: 'uploaded',
      label: 'Uploaded',
      date: true,
      width: '10%',
      sortable: true,
      searchable: true
    }
  ],

  styles: {
    dateSelector: {
      display: 'flex',
      padding: '12px',
      textAlign: 'center',
      verticalAlign: 'middle',
      flexWrap: 'wrap',
      alignItems: 'center',
      justifyContent: 'flex-end'
    },
    filterButton: {
      float: 'right',
      marginTop: '4px'
    }
  }
})
