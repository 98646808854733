<template>
  <PageContentLayoutOnly role="region">
    <div class="level">
      <div class="level-left">
        <b-button
          @click.prevent="createGoverningDocumentModal()"
          type="is-primary"
          rounded
          :style="{
            ...(breakpoint === 'desktop'
              ? {}
              : {
                  width: '100%'
                })
          }"
        >
          Add Document
        </b-button>
      </div>
    </div>
    <ModernTable
      :region="region"
      :filters="filters"
      :rows="rows"
      :columns="columns"
      :pagination="{
        perPage: 12
      }"
      :loading="loading"
      headerColor="#FFF"
      condensed
    >
    </ModernTable>
    <Modal :toggle.sync="toggle">
      <DocumentsModal :governingDocument="selectedGoverningDocument" />
    </Modal>
  </PageContentLayoutOnly>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import PageContentLayoutOnly from '@/containers/PageContentLayoutOnly'
import ModernTable from '@/components/tables/Modern/Table'
import DocumentsModal from './component/GoverningDocumentModal'
import Modal from '@/components/Modal'
import { windowStore } from '@/utilities/window/store'
import { methods } from './keys/methods'
import { data } from './keys/data'

export default {
  name: 'ManagementDocumentCenter',
  components: {
    PageContentLayoutOnly,
    ModernTable,
    DocumentsModal,
    Modal
  },

  data,

  methods,

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId,
      ...mapGetters('user', ['authUser', 'retrieveUser'])
    })
  },

  watch: {
    toggle() {
      if (this.toggle == false) {
        this.reload()
        this.toggle = false
        if (this.Modal && this.Modal != undefined) {
          this.Modal.$emit('update:toggle', (this.toggle = false))
        }
      }
    }
  },

  mounted() {
    this.reload()

    this.unsubWindowStore = windowStore.subscribe((mutation, state) => {
      const mType = mutation.type

      if (mType === 'breakPointChange') {
        this.breakpoint = state.breakpoint
      }
    })

    this.breakpoint = windowStore.state.breakpoint
  },

  i18n: {
    messages: {
      en: { documentCenter: { title: 'Document Center' } }
    }
  }
}
</script>

<style></style>
