export function data() {
  return {
    isDebug: true,
    isOpen: false,
    loading: true,
    isFetching: false,
    openOnFocus: true,
    buttonText: 'Add',
    file: null,
    base64pdf: '',
    scale: 1,
    imageContent: null,
    documentName: '',
    title: 'Create',
    attachment: false,
    governingDocumentID: 0,
    governingDocumentCategoryID: 0,
    categoryList: [],
    pageCount: []
  }
}
